
import { OrderViewModel } from "@/interfaces/OrderViewModel";
import router from "@/router";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import swal from "sweetalert";
import store from "@/store";

export default Vue.extend({
  name: "support-order-history",
  props: {
    payload: Object,
  },
  data() {
    return {
      orders: null as OrderViewModel[] | null,
      currentOrder: {},
      isCancelModalVisible: false,
      isRefundModalVisible: false,
      isConfirmRefundModalVisible: false,
      isChangeAmountModalVisible: false,
      isConfirmChangeAmountModalVisible: false,
      refundAmount: 0,
      changeChargeAmount: 0,
      refundNote: "",
      changeChargeNote: "",
    };
  },
  created() {
    this.init();
  },

  watch: {
    ordersVM() {
      this.init();
    },
  },
  computed: {
    ...mapGetters({
      Role: "getRole",
      FirstName: "getFirstName",
      LastName: "getLastName",
    }),

    maxRefundAmount(): number {
      if (this.currentOrder.totalRefunded === null) {
        return this.currentOrder.amount;
      } else {
        return this.currentOrder.amount - this.currentOrder.totalRefunded;
      }
    },
  },
  methods: {
    ...mapMutations({ SetLoading: "setLoading" }),
    async init() {
      this.SetLoading(true);
      const arVM = await store.dispatch("GetOrderHistory", { patientUserID: this.payload?.patientUserID, patientAffiliateID: this.payload?.patientAffiliateID, questionnaireTypeID: this.payload?.questionnaireTypeID, rxID: this.payload?.rxID });
      this.SetLoading(false);
      if (!arVM || !arVM.success) {
        swal("Error", "There was an error getting order history!", "error");
        return;
      }
      if (arVM.redirectRoute && arVM.redirectRoute.length > 0) {
        router.push(arVM.redirectRoute);
        return;
      }
      this.orders = arVM.data;
    },

    // Cancel Charge

    openModal(order: any) {
      this.currentOrder = order;
      this.isCancelModalVisible = true;
    },
    async confirmCancelCharge() {
      this.cancelCharge(this.currentOrder);
    },
    async cancelCharge(order) {
      this.SetLoading(true);
      const cancel = await store.dispatch("CancelCharge", { patientUserID: this.payload?.patientUserID, patientAffiliateID: this.payload?.patientAffiliateID, subscriptionID: order.subscriptionChargeQueueID, paymentTransactionType: encodeURIComponent(order.paymentTransactionType) });
      if (!cancel || !cancel.success) {
        swal("Error", "There was an error cancelling this charge!", "error");
        this.isCancelModalVisible = false;
        this.SetLoading(false);
        return;
      }

      this.init();

      this.$nextTick(() => {
        this.isCancelModalVisible = false;
        this.SetLoading(false);
      });
    },
    // Change Scheduled Charge

    openChangeAmountModal(order: any) {
      this.currentOrder = order;
      this.isChangeAmountModalVisible = true;
    },

    closeChangeAmountModal() {
      this.isChangeAmountModalVisible = false;
      this.isConfirmChangeAmountModalVisible = false;
      this.changeChargeAmount = 0;
      this.changeChargeNote = "";
      this.currentOrder = {};
    },

    async confirmChangeCharge() {
      this.isChangeAmountModalVisible = false;
      this.isConfirmChangeAmountModalVisible = true;
    },

    async changeCharge(order) {
      this.SetLoading(true);
      const change = await store.dispatch("UpdateScheduledCharge", {
        patientUserID: this.payload?.patientUserID,
        patientAffiliateID: this.payload?.patientAffiliateID,
        subscriptionID: this.currentOrder.subscriptionChargeQueueID,
        changeChargeAmount: this.changeChargeAmount,
        changeChargeNote: this.changeChargeNote,
        paymentTransactionType: encodeURIComponent(this.currentOrder.paymentTransactionType),
      });
      if (!change || !change.success) {
        swal("Error", "There was an error updating this charge!", "error");
        this.isChangeAmountModalVisible = false;
        this.isConfirmChangeAmountModalVisible = false;
        this.changeChargeAmount = 0;
        this.changeChargeNote = "";
        this.currentOrder = {};
        this.SetLoading(false);
        return;
      }

      this.init();

      this.$nextTick(() => {
        this.isChangeAmountModalVisible = false;
        this.isConfirmChangeAmountModalVisible = false;
        this.changeChargeAmount = 0;
        this.changeChargeNote = "";
        this.currentOrder = {};
        this.SetLoading(false);
      });
    },

    // Refund Charge

    openRefundModal(order: OrderViewModel) {
      this.currentOrder = order;
      this.isRefundModalVisible = true;
      console.log("currentOrder", this.currentOrder);
    },

    closeRefundModal() {
      this.isRefundModalVisible = false;
      this.isConfirmRefundModalVisible = false;
      this.refundAmount = 0;
      this.refundNote = "";
      this.currentOrder = {};
    },

    confirmRefund() {
      this.isRefundModalVisible = false;
      this.isConfirmRefundModalVisible = true;
    },

    async refundCharge() {
      this.SetLoading(true);
      const refund = await store.dispatch("RefundCharge", {
        patientUserID: this.payload?.patientUserID,
        patientAffiliateID: this.payload?.patientAffiliateID,
        questionnaireID: this.currentOrder.questionnaireID,
        transactionID: this.currentOrder.transactionID,
        refundAmount: this.refundAmount,
        paymentTransactionType: encodeURIComponent(this.currentOrder.paymentTransactionType),
        refundNote: this.refundNote,
      });
      if (!refund || !refund.success) {
        if (refund.errors[0] == "Refund previously issued.") swal("Error", "Refund previously issued.", "error");
        else swal("Error", "There was an error refunding this charge!", "error");
        this.isRefundModalVisible = false;
        this.isConfirmRefundModalVisible = false;
        this.refundAmount = 0;
        this.refundNote = "";
        this.currentOrder = {};
        this.SetLoading(false);
        return;
      }

      this.init();

      this.$nextTick(() => {
        this.isRefundModalVisible = false;
        this.isConfirmRefundModalVisible = false;
        this.refundAmount = 0;
        this.refundNote = "";
        this.currentOrder = {};
        this.SetLoading(false);
      });
    },
  },
});
