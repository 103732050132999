
import mixins from "vue-typed-mixins";
import CustomValidation from "@/mixins/CustomValidation";
import common from "@/mixins/Common";
import { BRow, BCol, BContainer, BJumbotron, BIcon, BFormSelect, BFormSelectOption, BModal } from "bootstrap-vue";
import { mapGetters, mapMutations } from "vuex";
import { PatientPortalViewModel } from "@/interfaces/PatientPortalViewModel";
import { PatientMessagesPayloadViewModel } from "@/interfaces/PatientMessagesPayloadViewModel";
import { PatientPortalRx } from "@/interfaces/PatientPortalRx";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
import { ToggleButton } from "vue-js-toggle-button";
import PatientMessagesVueComponent from "@/components/PatientMessagesVueComponent.vue";
import MessagingVueComponent from "@/components/MessagingVueComponent.vue";
import PatientProfileVueComponent from "@/components/PatientProfileVueComponent.vue";
import SupportOrderHistoryVueComponent from "@/components/SupportOrderHistoryVueComponent.vue";
import RxHistoryVueComponent from "@/components/RxHistoryVueComponent.vue";
import LabResults from "@/components/LabResultsVueComponent.vue";
import PatientWidgetData from "@/components/PatientWidgetDataVueComponent.vue";
import router from "@/router";
import lottie from "lottie-web";
import store from "@/store";
import moment from "moment";
import swal from "sweetalert";
import { PatientPortalRxContainer } from "@/interfaces/PatientPortalRxContainer";
import { PatientPortalPaidShoppingCartViewModel } from "@/interfaces/PatientPortalPaidShoppingCartViewModel";
import { ShoppingCartViewModel } from "@/interfaces/ShoppingCartViewModel";
import { PatientPortalShoppingCartItem } from "@/interfaces/PatientPortalShoppingCartItem";
import { ShoppingCartItem } from "@/interfaces/ShoppingCartItem";
import Badge from "primevue/badge";

export interface IBookingWidget {
  [x: string]: any;
  initialize: (arg0: any, arg1: any) => void;
}

export interface IGReminders {
  BookingWidget: IBookingWidget;
}

const GReminders: IGReminders = (window as any).GReminders;

export default mixins(CustomValidation, common).extend({
  data() {
    return {
      vm: null as PatientPortalViewModel | null,
      msgVM: {
        onSendCallback: null,
        newMessageCountCallback: null,
        noNewMessagesCallback: null,
        patientId: 0,
        patientAffiliateID: 0,
        questionnaireTypeID: 0,
        isDoctor: false,
        isSupport: false,
        isPharmacy: false,
      } as PatientMessagesPayloadViewModel,
      ppVM: {
        onCancelCB: () => {
          this.$bvModal.hide("modal-pp");
        },
        onSavedCB: () => {
          this.$bvModal.hide("modal-pp");
        },
      },
      orderVM: {
        patientUserID: 0,
        patientAffiliateID: 0,
        questionnaireTypeID: 0,
        rxID: 0,
      },
      showMessages: false as boolean,
      openToggle: true as boolean,
      labTests: false as boolean,
      isCollapsed: true,
      showFull: false as boolean,
      displayWeightTrackerWidgetData: false as boolean,
    };
  },
  async mounted() {
    this.Refresh();
    window.setTimeout(() => {
      lottie.loadAnimation({
        container: document.getElementsByClassName("medical-shield")[0] as Element, // the dom element that will contain the animation
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/img/medical-shield.json", // the path to the animation json
      });
    }, 1000);

    if (this.Content.affiliateAllowDisplayPatientWidgets && this.Content.affiliateAllowDisplayPatientWidgets.displayWeightTracker) {
      const getData = await store.dispatch("GetFullPatientWidgetData");
      if (getData.wtData && getData.wtData.weightLogEntries.length > 0) {
        this.displayWeightTrackerWidgetData = this.Content.affiliateAllowDisplayPatientWidgets.displayWeightTracker;
      }
    }
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      ShoppingCart: "getShoppingCart",
    }),
    GreetingTime(): string {
      var g = null; //return g
      var m = moment();

      if (!m || !m.isValid()) {
        return "";
      } //if we can't find a valid or filled moment, we return.

      var split_afternoon = 12; //24hr time to split the afternoon
      var split_evening = 17; //24hr time to split the evening
      var currentHour = parseFloat(m.format("HH"));

      if (currentHour >= split_afternoon && currentHour <= split_evening) {
        g = "afternoon";
      } else if (currentHour >= split_evening) {
        g = "evening";
      } else {
        g = "morning";
      }

      return g;
    },
  },
  watch: {},
  methods: {
    ...mapMutations({ setCurrentQuestType: "setCurrentQuestType", setCurrentRxID: "setCurrentRxID", setCurrentRefillNum: "setCurrentRefillNum", SetLoading: "setLoading", setShowMainMenu: "setShowMainMenu" }),
    async Refresh() {
      const result = await store.dispatch("GetPatientPortal");
      if (result && result.success) {
        this.vm = result.data;

        this.vm?.rxs.forEach((x) => {
          x.rxs.sort((a, b) => this.getContentJSONOrder(a) - this.getContentJSONOrder(b));
        });
        this.vm?.renewalRxs.forEach((x) => {
          x.rxs.sort((a, b) => this.getContentJSONOrder(a) - this.getContentJSONOrder(b));
        });

        this.msgVM = {
          onSendCallback: null,
          newMessageCountCallback: (count) => {
            if (this.vm) this.vm.unreadMessages = count;
          },
          noNewMessagesCallback: null,
          patientId: this.vm?.patient.id ? this.vm?.patient.id : 0,
          patientAffiliateID: this.vm?.patient.affiliateID ? this.vm?.patient.affiliateID : 0,
          questionnaireTypeID: 0,
          isDoctor: false,
          isSupport: false,
          isPharmacy: false,
          allowedFolderTypes: this.vm?.allowedFolderTypes,
          rxs: this.vm?.allowedRxs,
        };
      }
    },
    getShowDirections(rx: PatientPortalRx): boolean | null {
      if (rx.apRxContentJSON) {
        return JSON.parse(rx.apRxContentJSON).ShowDirections;
      }
      return null;
    },
    getRxTitleOverride(rx: PatientPortalRx): string | null {
      if (rx.apRxContentJSON) {
        return JSON.parse(rx.apRxContentJSON).RxTitleOverride;
      }
      return null;
    },
    getGroupRxFooter(rx: PatientPortalRx): string | null {
      if (rx.apRxContentJSON) {
        return JSON.parse(rx.apRxContentJSON).GroupRxFooter;
      }
      return null;
    },
    getContentJSONOrder(rx: PatientPortalRx): number {
      if (rx.apRxContentJSON) {
        return isNaN(JSON.parse(rx.apRxContentJSON).PatientPortalOrder) ? 9999 : parseInt(JSON.parse(rx.apRxContentJSON).PatientPortalOrder);
      }
      return 9999;
    },
    async OrderAgain(newCart: PatientPortalPaidShoppingCartViewModel) {
      var cart = this.ShoppingCart;
      if (!cart) {
        cart = {} as ShoppingCartViewModel;
        cart.items = [];
      }

      const items: ShoppingCartItem[] = [];

      newCart.items.forEach((item: PatientPortalShoppingCartItem) => {
        const id = this.ShoppingCart?.items?.length > 0 ? this.ShoppingCart?.items[this.ShoppingCart?.items?.length - 1].sid + 1 : 1;
        const nitem: ShoppingCartItem = {
          id: null,
          sid: id,
          qty: item.qty,
          productID: item.productID,
          productTypeID: 0,
          markup: 1,
          pricePerUnit: item.productPrice,
          price: item.productPrice,
          affiliateMarkupPercent: item.affiliateMarkupPercent,
          active: true,
          total: item.subTotal,
          createdDate: new Date(),
          modifiedDate: new Date(),
        };
        items.push(nitem);
      });

      const rsp = await store.dispatch("SaveShoppingCartItem", items);
      if (rsp) {
        const c = rsp.cart as ShoppingCartViewModel;
        c.visible = true;
        c.shown = true;
        store.commit("setShoppingCart", c);
        store.commit("setShowCart", true);
      } else {
        console.log("error adding item to cart");
      }
      return;
    },
    FinishVisit(t: number) {
      this.setCurrentQuestType(t);
      router.push("/questionnaire");
    },
    Pay(r: PatientPortalRxContainer) {
      if (!r.questionnaireID && r.rxID && r.rxID > 0) {
        this.setCurrentRxID(r.rxID);
        router.push("/rx/payment");
        return;
      }
      this.setCurrentQuestType(r.questionnaireTypeID);
      router.push("/payment");
    },
    SubChargePay(r: PatientPortalRxContainer) {
      this.setCurrentQuestType(r.questionnaireTypeID);
      router.push("/subchargepayment");
    },
    Refill(r: PatientPortalRxContainer) {
      if (!r.questionnaireID && r.rxID && r.rxID > 0) {
        this.setCurrentRxID(r.rxID);
        this.setCurrentRefillNum(r.numberOfRefillsRemaining);
        router.push("/rx/refill");
        return;
      }
      this.setCurrentQuestType(r.questionnaireTypeID);
      this.setCurrentRefillNum(r.numberOfRefillsRemaining);
      router.push(`/refill`);
    },
    Modify(r: PatientPortalRxContainer) {
      this.setCurrentQuestType(r.questionnaireTypeID);
      this.setCurrentRefillNum(r.numberOfRefillsRemaining);
      router.push(`/modify`);
    },
    UpdatePhotos(t: number) {
      this.setCurrentQuestType(t);
      router.push("/photos");
    },
    async Renew(t: number) {
      this.SetLoading(true);
      var tResult: AjaxReturnViewModel<boolean> = await store.dispatch("Renew", t);
      this.SetLoading(false);
      if (!tResult || !tResult.success || !tResult.data) {
        this.SetLoading(false);
        swal("Error", "There was an error renewing your Rx! Please refresh and try again.", "error");
        return;
      }
      this.setCurrentQuestType(t);
      router.push("/questionnaire");
    },
    Reschedule(rx: PatientPortalRxContainer) {
      GReminders.BookingWidget.initialize(
        //create an event type and put the link below: https://app.greminders.com/event-types
        rx.calendlyUrl,
        {
          fields: {
            first_name: this.vm?.patient.firstName, //you can prepopulate the fields here if you already have this information
            last_name: this.vm?.patient.lastName,
            email: this.vm?.email,
            c_customdata: JSON.stringify({ QuestionnaireID: rx.questionnaireID, AffiliateID: this.vm?.patient.affiliateID, Reschedule: true }), //populate custom questions/fields as well: https://app.greminders.com/admin/custom-questions
            auto_submit: true, //to auto submit the form set this true, default is false, note the form must pass validation
          },
        }
      );
      GReminders.BookingWidget.onSuccess((event_id: string | number) => {
        //we automatically close the widget on successful booking, you can continue your flow here
        GReminders.BookingWidget.close();
        swal("Your visit has been rescheduled!", "The new visit may take a few minutes to show up in the dashboard. You may refresh the screen to check for the updated time.", "success");
      });
      GReminders.BookingWidget.onError((message: string | number) => {
        //if we encounter a booking error you can trigger a different error or retry
        GReminders.BookingWidget.close();
        swal("Error Scheduling Appointment", "Please try scheduling again.", "error");
      });
      GReminders.BookingWidget.open();
    },
    StartVisit(cl: string) {
      window.open(cl, "_blank");
    },
    ShowRxHistory() {
      this.$bvModal.show("modal-rx-history");
    },
    ShowMessages() {
      this.showMessages = true;
      this.$bvModal.show("modal-msg");
    },
    ShowPatientProfile() {
      this.$bvModal.show("modal-pp");
    },
    ShowLabResults() {
      this.$bvModal.show("modal-lab");
    },
    ShowRefillConfirmation() {
      this.$bvModal.show("modal-refill");
    },
    CloseRefillConfirmation() {
      this.$bvModal.hide("modal-refill");
    },
    ShowWeightTrackerModal() {
      this.$bvModal.show("modal-weight");
    },
    ShowMedTrackerModal() {
      this.$bvModal.show("modal-med");
    },
    async ToggleSubscription(rx: PatientPortalRxContainer) {
      if (rx.isSubscription) {
        swal({
          text: "You can turn Auto-Refill back on during your next refill if desired.",
          title: "Are you sure you want to turn off Auto-Refill?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(async (result) => {
          if (result) {
            var tResult: AjaxReturnViewModel<boolean> = await store.dispatch("ToggleSubscription", rx.questionnaireTypeID);
            if (!tResult || !tResult.success || !tResult.data) {
              this.SetLoading(false);
              swal("Error", "There was an error turning Auto-Refill off! Please refresh and try again.", "error");
              return;
            }
            this.SetLoading(false);
            this.Refresh();
            return;
          }
        });
      } else {
        swal({
          text: "You can turn Auto-Refill back on during your next refill if desired.",
          title: "Auto-Refill",
          icon: "info",
        });
        rx.isSubscription = false;
        let rxs = this.vm?.rxs.filter((r) => r.questionnaireID != rx.questionnaireID && r.rxID != rx.rxID);
        rxs?.push(rx);
        this.vm.rxs = rxs as PatientPortalRxContainer[];
      }
    },
    Track(rx: PatientPortalRx | PatientPortalShoppingCartItem) {
      swal("Warning", "You are about to leave our site and navigate to a shipping carrier site.", "warning").then(() => {
        window.open(rx.trackingString.replace("{0}", rx.trackingNumber), "_blank");
      });
    },
    ShowOrderHistory(questionnaireTypeID: number, rxID: number) {
      this.orderVM = {
        patientUserID: this.vm?.patient.userID ? this.vm?.patient.userID : 0,
        patientAffiliateID: this.vm?.patient.affiliateID ? this.vm?.patient.affiliateID : 0,
        questionnaireTypeID: questionnaireTypeID,
        rxID: rxID,
      };
      this.$bvModal.show("modal-order");
    },
    nameIconToggle(ID: number, isAfterFirstItem: boolean) {
      if (this.isCollapsed[ID] === undefined) {
        this.$set(this.isCollapsed, ID, isAfterFirstItem);
      }
      this.$set(this.isCollapsed, ID, !this.isCollapsed[ID]);
    },
    showMainMenu() {
      this.setShowMainMenu(true);
    },
  },
  components: {
    BRow,
    BCol,
    BContainer,
    BJumbotron,
    BIcon,
    ToggleButton,
    PatientMessagesVueComponent,
    PatientProfileVueComponent,
    SupportOrderHistoryVueComponent,
    BFormSelect,
    BFormSelectOption,
    BModal,
    LabResults,
    MessagingVueComponent,
    RxHistoryVueComponent,
    PatientWidgetData,
    Badge,
  },
});
