
import mixins from "vue-typed-mixins";
import CustomValidation from "@/mixins/CustomValidation";
import common from "@/mixins/Common";
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
import swal from "sweetalert";
import { PatientMessageView } from "@/interfaces/PatientMessageView";
import { FolderTypeEnum } from "@/interfaces/FolderTypeEnum";
import { PatientMessagesPayloadViewModel } from "@/interfaces/PatientMessagesPayloadViewModel";
import store from "@/store";
import { PropType } from "vue";
import { BIcon } from "bootstrap-vue";
import { PatientMessageViewModel } from "@/interfaces/PatientMessagesViewModel";
import { data } from "jquery";
import PicInputVueComponentVue from "@/components/PicInputVueComponent.vue";

export default mixins(CustomValidation, common).extend({
  name: "messaging-component",
  props: {
    payload: Object as PropType<PatientMessagesPayloadViewModel>,
    noNewMessage: { default: false, type: Boolean },
    rxs: {
      type: Array,
    },
  },
  mixins: [common],
  data() {
    return {
      PatientMessages: [] as PatientMessageView[],
      CannedMessages: [] as string[],
      NewMessage: "" as string | null,
      folderType: -99,
      rxID: null as number | null,
      showMessages: [] as number[],
      folderCount: [] as number[],
      folders: [] as number[],
      FolderTypeEnum: FolderTypeEnum,
      ifNoMessages: false as boolean,
      step: 0 as number,
      rxPharmacies: [] as Record<number, string>,
      activeFolder: null as number | null,
      chatLoading: false as boolean,
      chatFolder: null as number | null,
      supportPopupContent: "" as string,
      patientFolders: [] as number[],
      tempPatientMessageID: null as number | null,
      tempPatientAffiliateID: null as number | null,
      tempPatientQuestionnaireID: null as number | null,
      tempPatientUserID: null as number | null,
      tempfolderType: null as number | null,
      fileUploadBase64: null as string | null,
      attachmentType: null as number | null,
      showAttachmentModal: false,
      fullSizeImage: "" as string,
    };
  },
  mounted() {
    this.reset();
    if (this.payload.isDoctor) this.getCannedMessages();
    if (this.payload.isSupport) this.folderType = 0;
    if (this.payload.isPharmacy) this.folderType = 999;
  },
  updated() {
    this.scrollToBottom();
  },
  watch: {
    payload: {
      handler(newValue) {
        this.reset();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      Affiliate: "getAffiliate",
      Loading: "getLoading",
      Content: "getContent",
    }),
    rxsWithMessages(): { rxID: number; displayName: string }[] {
      var result = [] as any[];
      this.payload.rxs?.forEach((rx) => {
        var msgExists = this.PatientMessages.findIndex((x) => x.rxID == rx.rxID);
        if (msgExists >= 0) {
          result.push(rx);
        }
      });
      return result;
    },
    currentFolder(): PatientMessageView[] {
      var result = [] as PatientMessageView[];
      this.PatientMessages.forEach((v) => {
        if (v.folderType == this.folderType && this.folderType == 999) {
          if (v.rxID == this.rxID) {
            result.push(v);
          }
        } else {
          if (v.folderType == this.folderType) result.push(v);
        }
      });
      return result;
    },
    medicalFolderUnread(): number {
      var unread = this.medicalFolder.filter((obj) => {
        return obj.readDate === null;
      });
      return unread ? unread.length : 0;
    },
    medicalFolderCount(): number {
      return this.medicalFolder ? this.medicalFolder.length : 0;
    },
    medicalFolder(): PatientMessageView[] {
      var result = [] as PatientMessageView[];
      this.PatientMessages.forEach((v) => {
        if (v.folderType != 0) result.push(v);
      });
      return result;
    },
    supportFolderUnread(): number {
      var unread = this.supportFolder.filter((obj) => {
        return obj.readDate === null;
      });
      return unread ? unread.length : 0;
    },
    supportFolderCount(): number {
      return this.supportFolder ? this.supportFolder.length : 0;
    },
    supportFolder(): PatientMessageView[] {
      var result = [] as PatientMessageView[];
      this.PatientMessages.forEach((v) => {
        if (v.folderType == 0) result.push(v);
      });
      return result;
    },
    sortedMessages(): PatientMessageView[] {
      return this.currentFolder.sort((a, b) => {
        return moment(a.createdDate).toDate().getTime() - moment(b.createdDate).toDate().getTime();
      });
    },
    hasUnreadMessages(): boolean {
      return this.currentFolder.some((message) => !message.readDate);
    },
    currentRxFolderName(): string {
      console.log(`currentRxFolderName rxID ${this.rxID}`);
      if (!this.rxID) return "";
      console.log(this.rxsWithMessages);
      var rx = this.rxsWithMessages.filter((x) => x.rxID == this.rxID);
      if (!rx || rx.length == 0) return "";
      return rx[0].displayName;
    },
    currentQuestFolderName(): string {
      return this.getQuestFolderName(this.folderType);
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setQuestionnaire: "setQuestionnaire",
    }),
    getQuestFolderName(qtID: number): string {
      if (!qtID) return "";

      if (this.Role != "Support" && this.Role != "AffiliateAdmin" && this.Role != "Patient") {
        const quest = this.payload?.quests?.find((x) => x.questionnaireTypeID == qtID);
        return quest ? quest.displayName : "";
      }
      if (this.Role == "Patient") {
        const quest = this.payload?.allowedFolderTypes?.find((x) => x.questionnaireTypeID == qtID);
        if (quest) return quest.displayName;
      } else {
        const rxsMatch = this.rxs.find((x) => x.questionnaireTypeID == qtID);
        return rxsMatch ? rxsMatch.questionnaireTypeDisplayName : "";
      }
    },
    newMessageClick() {
      if (this.payload.isSupport) {
        this.filter(0);
      } else {
        if (this.payload.isPharmacy) {
          this.filter(999);
        } else {
          this.filter(-1);
        }
      }
    },
    filter(i: number) {
      this.step = 3;
      this.folderType = i;
      this.activeFolder = i;
      this.currentFolder.forEach((msg: PatientMessageView) => {
        if (msg.readDate === null) {
          this.markPatientMessageAsRead(msg);
        }
        this.$nextTick(() => {
          const scrollContainer = this.$refs.scrollContainer as HTMLElement;
          const scrollDesktopContainer = this.$refs.scrollDesktopContainer as HTMLElement;
          if (scrollContainer || scrollDesktopContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
            scrollDesktopContainer.scrollTop = scrollDesktopContainer.scrollHeight;
          }
        });
      });
    },
    async btnFWDToSupport_Click(patientMessageID: number, patientAffiliateID: number) {
      var postData = { patientMessageID: patientMessageID, patientAffiliateID: patientAffiliateID };
      this.setLoading(true);
      const result = await store.dispatch("FWDMSGToSupport", postData);
      this.setLoading(false);
      if (!result) {
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
        return;
      }
      swal({
        text: "",
        title: "Message sent!",
        icon: "success",
      }).then(() => {
        if (this.payload.onSendCallback) {
          this.payload.onSendCallback();
        }
      });
    },
    async btnFWDToPhysician_Click(patientMessageID: number, patientAffiliateID: number, patientUserID: number) {
      this.tempPatientMessageID = patientMessageID;
      this.tempPatientAffiliateID = patientAffiliateID;
      this.tempPatientUserID = patientUserID;

      const folderTypes = this.rxs.map((item) => item.questionnaireTypeID);
      this.patientFolders = folderTypes;

      this.step = 2;
    },
    async btnFWDToPhysician_Submit(patientMessageID: number, patientAffiliateID: number, questionnaireTypeID: number, patientUserID: number) {
      var postData = { patientMessageID: patientMessageID, patientAffiliateID: patientAffiliateID, questionnaireTypeID: questionnaireTypeID, patientUserID: patientUserID };
      this.setLoading(true);
      const result = await store.dispatch("FWDMSGToPhysician", postData);
      this.setLoading(false);
      if (!result) {
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
        return;
      }
      swal({
        text: "",
        title: "Message sent!",
        icon: "success",
      }).then(() => {
        if (this.payload.onSendCallback) {
          this.payload.onSendCallback();
        }
      });
    },
    btnMedical_Click() {
      this.folderType = 0;
    },
    btnSupport_Click() {
      this.folderType = 1;
    },
    async reset() {
      this.NewMessage = "";
      await this.getPatientMessages();
    },
    async getPatientMessages() {
      var postData = { patientId: this.payload.patientId, patientAffiliateID: this.payload.patientAffiliateID, questionnaireTypeID: this.payload.questionnaireTypeID };

      const result = await store.dispatch("GetPatientMessages", postData);
      if (!result) {
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
        return;
      }
      this.PatientMessages = [];
      this.rxPharmacies = result.rxPharmacies;
      var array: PatientMessageView[] = result.patientMessages;
      var fc = Array.apply(null, Array(1000)).map(() => {
        return 0;
      });
      var fs = [] as number[];
      array.forEach((v) => {
        this.PatientMessages.push(v);
        fc[v.folderType] += 1;
        console.log(this.folderCount[v.folderType]);
        console.log(v.folderType);
      });
      fc.forEach((f, i) => {
        if (f > 0) {
          fs.push(i);
        }
      });
      if (this.payload.isDoctor) this.filter(this.payload.allowedFolderTypes[0]);
      if (this.payload.isSupport) this.filter(0);
      if (this.payload.isPharmacy) {
        this.filter(999);
        this.step = 0;
      }
      this.folderCount = fc;
      this.folders = fs;
      this.updateCount();
      this.setLoading(false);
    },
    async getCannedMessages() {
      this.setLoading(true);
      const result = await store.dispatch("GetCannedMessages");
      if (!result) {
        this.setLoading(false);
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
        return;
      }
      var array = result as string[];
      array.forEach((v) => {
        this.CannedMessages.push(v);
      });
      this.setLoading(false);
    },
    async sendPatientMessage() {
      if (this.NewMessage.trim() === "" && this.fileUploadBase64 === null) {
        this.$bvToast.toast("Message cannot be empty! Please enter a message and try again.", { title: "Error", autoHideDelay: 3000, appendToast: true, variant: "warning", solid: true, toaster: "b-toaster-top-center" });
        return false;
      }
      if (this.folderType < 0) {
        this.$bvToast.toast("Please let us know what this message is in regard to.", { title: "Error", autoHideDelay: 3000, appendToast: true, variant: "warning", solid: true, toaster: "b-toaster-top-center" });
        return false;
      }

      // Determine the attachment type
      if (this.fileUploadBase64) {
        this.attachmentType = 1; // Defaults to picture for now
        this.NewMessage = null;
      }
      this.setChatLoading(true);
      var postData = { patientId: this.payload.patientId, patientAffiliateID: this.payload.patientAffiliateID, message: this.NewMessage, folderType: this.folderType, rxID: this.rxID, attachment: this.fileUploadBase64, attachmentType: this.attachmentType };
      const result = await store.dispatch("SendPatientMessage", postData);
      if (!result) {
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true, variant: "danger", solid: true, toaster: "b-toaster-top-center" });
        return;
      }
      this.showAttachmentModal = false;

      this.NewMessage = "";
      this.getPatientMessages().then(() => {
        // Assuming getPatientMessages returns a Promise

        this.setChatLoading(false);

        this.scrollToBottom();

        this.$bvToast.toast("Message Sent!", {
          title: "Success",
          autoHideDelay: 3000,
          appendToast: true,
          variant: "success",
          solid: true,
          toaster: "b-toaster-top-center",
        });

        if (this.payload && this.payload.onSendCallback) {
          this.payload.onSendCallback();
        }
      });
    },
    updateCount() {
      if (!this.payload.newMessageCountCallback) return;
      var unreadCount = 0;
      this.PatientMessages.forEach((v) => {
        if (this.payload.isDoctor && v.readDate === null && v.folderType > 0) {
          unreadCount++;
        } else {
          if (this.payload.isSupport && v.readDate === null && v.folderType == 0) {
            unreadCount++;
          } else {
            if (this.payload.isPharmacy && v.readDate === null && v.folderType == 999) {
              unreadCount++;
            } else {
              if (v.readDate === null) {
                unreadCount++;
              }
            }
          }
        }
      });
      this.payload.newMessageCountCallback(unreadCount);
    },
    async markPatientMessageAsRead(message: PatientMessageView) {
      if (message.readDate === null) {
        message.readDate = new Date().toDateString();
        var postData = { messageId: message.id, patientAffiliateID: message.affiliateID };
        const result = await store.dispatch("MarkPatientMessageAsRead", postData);
        if (!result) {
          this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
          return;
        }
        this.toggleShowMessage(message.id);

        var msgs = [] as PatientMessageView[];
        this.PatientMessages.forEach((v) => {
          if (v.id == message.id) msgs.push(message);
          else msgs.push(v);
        });
        this.PatientMessages = [] as PatientMessageView[];
        this.PatientMessages = msgs;

        if (this.payload && this.payload.noNewMessagesCallback) {
          if (this._hasUnreadMessages() === false) {
            this.payload.noNewMessagesCallback();
          }
        }
        this.updateCount();
      } else {
        this.toggleShowMessage(message.id);
      }
    },
    toggleShowMessage(ID: number) {
      var foundI = -1;
      for (var i = 0; i < this.showMessages.length; i++) {
        if (this.showMessages[i] == ID) foundI = i;
      }
      if (foundI > -1) {
        this.showMessages.splice(foundI, 1);
      } else {
        this.showMessages.push(ID);
      }
    },
    showMessage(ID: number) {
      var foundID = this.showMessages.filter((obj) => {
        return obj === ID;
      });
      return foundID && foundID.length > 0 ? true : false;
    },
    formatMessageDate(d: string) {
      var local = moment(moment.utc(d)).local();
      return local.format("MM/DD/YYYY h:mm A");
    },
    _hasUnreadMessages(folderType: number, rxID: number | null) {
      var result = false;
      var message = this.PatientMessages.filter((obj: PatientMessageView) => {
        if (folderType == obj.folderType && (!rxID || rxID == obj.rxID)) return obj.readDate === null;
      });

      if (message.length > 0) {
        result = true;
      } else {
        result = false;
      }

      return result;
    },
    messageClick(msg: string) {
      if (!this.payload.isDoctor) return;
      this.NewMessage = msg;
    },
    startNewMessage() {
      if (this.Role === "Pharmacy") {
        this.step = 21;
      } else if (this.Role === "Physician") {
        this.step = 2;
      } else {
        this.rxID = null;
        this.step = 1;
      }
    },
    selectOption(value: number) {
      if (value === 0) {
        const contentJSON = JSON.parse(this.Affiliate.contentJSON);

        if (contentJSON.ContactCustomerSupportOverrideLink || contentJSON.ContactCustomerSupportOverridePopupInfo) {
          if (contentJSON.ContactCustomerSupportOverrideLink) {
            window.open(contentJSON.ContactCustomerSupportOverrideLink, "_blank");
          }
          if (contentJSON.ContactCustomerSupportOverridePopupInfo) {
            this.supportPopupContent = contentJSON.ContactCustomerSupportOverridePopupInfo;
            this.$bvModal.show("override-contact-popup-modal");
          }
          return;
        }

        this.folderType = value;
        this.step = 3;
      } else {
        if (value === 999) {
          this.folderType = value;
          this.step = 21;
          console.log(this.rx);
        } else {
          this.folderType = value;
          this.step = 2;
        }
      }
      console.log(this.folderType);
    },
    selectProgram(value: number) {
      if (this.Role != "Support" && this.Role != "AffiliateAdmin") {
        this.folderType = value;
        this.step = 3;
        console.log(this.folderType);
      } else {
        this.btnFWDToPhysician_Submit(this.tempPatientMessageID, this.tempPatientAffiliateID, value, this.tempPatientUserID);
        this.step = 3;
      }
    },
    selectRx(rxID: number) {
      const rx = this.payload.rxs.find((r) => r.rxID === rxID);

      if (rx && (rx.contactPharmacyOverrideLink || rx.contactPharmacyOverridePopupInfo)) {
        if (rx.contactPharmacyOverrideLink) {
          window.open(rx.contactPharmacyOverrideLink, "_blank");
        }

        if (rx.contactPharmacyOverridePopupInfo) {
          this.supportPopupContent = rx.contactPharmacyOverridePopupInfo;
          this.$bvModal.show("override-contact-popup-modal");
        }
        return;
      }

      this.folderType = 999;
      this.rxID = rxID;
      this.activeFolder = rxID;
      this.step = 3;
      this.currentFolder.forEach((msg: PatientMessageView) => {
        if (msg.readDate === null) {
          this.markPatientMessageAsRead(msg);
        }
      });

      this.$nextTick(() => {
        const scrollContainer = this.$refs.scrollContainer as HTMLElement;
        const scrollDesktopContainer = this.$refs.scrollDesktopContainer as HTMLElement;
        if (scrollContainer || scrollDesktopContainer) {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
          scrollDesktopContainer.scrollTop = scrollDesktopContainer.scrollHeight;
        }
      });
    },
    viewAllMessages() {
      this.step = 0;
    },
    goBack() {
      if (this.step === 21 && this.Role === "Pharmacy") {
        this.step = 0;
      } else if (this.step === 21) {
        this.step = 1;
      } else if (this.step === 2 && this.Role === "Physician") {
        this.step = 3;
      } else if (this.step === 2 && (this.Role === "Support" || this.Role === "AffiliateAdmin")) {
        this.step = 3;
      } else {
        this.step--;
      }
    },
    setChatLoading(load: boolean) {
      this.chatLoading = load;
      console.log("Chat Loading: " + this.chatLoading);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const scrollContainer = this.$refs.scrollContainer as HTMLElement | undefined;
        const scrollDesktopContainer = this.$refs.scrollDesktopContainer as HTMLElement | undefined;
        if (scrollContainer && scrollDesktopContainer) {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
          scrollDesktopContainer.scrollTop = scrollDesktopContainer.scrollHeight;
        } else {
          console.error("Scroll container not found");
        }
      });
    },
    autoResize(event: Event) {
      const target = event.target as HTMLTextAreaElement;
      target.style.height = "auto";
      target.style.height = target.scrollHeight + "px";
    },
    collapseTextarea(event: any) {
      const target = event.target;
      target.style.height = "42px";
    },
    expandTextarea(event: any) {
      const target = event.target;
      target.style.height = target.scrollHeight + "px";
    },
    openFullSizeImage(image: any) {
      this.fullSizeImage = image;
      this.$bvModal.show("modal-img");
    },
  },
  components: { BIcon, PicInputVueComponentVue },
});
